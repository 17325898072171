<template>
  <div class="cont">
    <h3>Timetable Report</h3>
    <div class="stats d-flex flex-column">
      <div class="row w-100">
        <div class="col">
          <h5>From {{ date1 }}</h5>
        </div>
        <div class="col">
          <h5>To {{ date2 }}</h5>
        </div>
        <br /><br />
      </div>

      <!-- Time table stats -->
      <div class="plots">
        <div v-for="(item, index) in imglist" :key="index">
          <img :src="item" />
        </div>
      </div>
    </div>

    <div class="form-control d-flex justify-content-center">
      <input
        type="text"
        class="form-control"
        placeholder="Add Review Here"
        v-model="teacher_review"
      />

      <button class="btn btn-dark" @click="saveReview">
        Save Review and Delete Notification
      </button>
    </div>
    <div><button class="btn btn-light" @click="redirect">Go Back</button></div>
  </div>
</template>
<style scoped>
.stats {
  width: 80%;
  margin: auto;
  border-style: inset;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}
.cont {
  margin-top: 20px;
  text-align: center;
}
.btn {
  margin: 30px;
}
.text {
  font-style: italic;
}
.plots {
  object-fit: contain;
}
.plots img {
  max-width: 70%;
}
</style>
<script>
import firebase from "firebase";
import store from "@/store";

import db from "../../firebase";
export default {
  name: "ReviewTimeTable",
  props: ["user_id", "timestamp"],
  data() {
    return {
      teacher_review: "",
      imglist: [],
      date1: "",
      date2: "",
      index: -1
    };
  },
  mounted() {
    // console.log(this.$route.params);
    db.collection("user_details")
      .doc(this.user_id)
      .get()
      .then(doc => {
        // this.date1 = doc.data()["time_table_date1"];
        // this.date2 = doc.data()["time_table_date2"];

        // console.log(doc.data().timetable);
        let timetable = doc.data().timetable;
        for (let i = 0; i < timetable.length; ++i) {
          // console.log(timetable[i]);
          if (timetable[i].timeStamp == this.timestamp) {
            this.index = i;
            this.date1 = timetable[i]["time_table_date1"];
            this.date2 = timetable[i]["time_table_date2"];
          }
        }
      });
    for (var i = 0; i < 5; i++) {
      //Fetching all images for this user
      firebase
        .storage()
        .ref()
        .child(
          "timetable/" +
            this.user_id +
            "/" +
            this.timestamp +
            "/img" +
            i +
            ".png"
        )
        .getDownloadURL()
        .then(
          foundUrl => {
            this.imglist.push(foundUrl);
            this.found = true;
          },
          err => {
            //Failure
            err;
          }
        );
    }
    // this.found;'
    store.state.loading = false;
  },
  methods: {
    async saveReview() {
      var d = await db
        .collection("user_details")
        .doc(this.user_id)
        .get();
      console.log(d.data());
      console.log(this.index);
      var timetable = d.data();
      timetable.timetable[this.index]["review"] = this.teacher_review;
      timetable.timetable[this.index]["timetable_is_reviewed"] = true;

      db.collection("user_details")
        .doc(this.user_id)
        .set(timetable)
        .then(r => {
          console.log(r);
        });
      db.collection("time_table_notifications")
        .doc(this.user_id)
        .delete()
        .then(() => {
          this.redirect();
        })
        .catch(e => alert(e));
    },
    redirect() {
      this.$router.push({ name: "TimeTableNotifications" });
    }
  }
};
</script>
